<template>
  <div>
    <div class="max-w-screen-sm mx-auto">
      <p class="uppercase tracking-wider text-2xl mb-2">identitas sekolah</p>
      <form @submit.prevent="submitIdentity()">
        <section>
          <label for="school-name" class="form-label">Nama Sekolah</label>
          <input type="text" id="school-name" v-model="form.name" />
        </section>
        <section>
          <label for="school-a" class="form-label">alamat</label>
          <input type="text" id="school-a" v-model="form.address" />
        </section>
        <section>
          <label for="school-t" class="form-label">tagline</label>
          <input type="text" id="school-t" v-model="form.tagline" />
        </section>
        <section>
          <label for="school-c" class="form-label">kontak</label>
          <input type="text" id="school-c" v-model="form.contact" />
        </section>
        <section>
          <label for="school-w" class="form-label">whatsapp</label>
          <input type="text" id="school-w" v-model="form.whatsapp" />
        </section>
        <section>
          <label for="school-i" class="form-label">instagram</label>
          <input type="text" id="school-i" v-model="form.instagram_url" />
        </section>
        <section>
          <label for="school-f" class="form-label">facebook</label>
          <input type="text" id="school-f" v-model="form.facebook_url" />
        </section>
        <section>
          <label for="school-y" class="form-label">youtube</label>
          <input type="text" id="school-y" v-model="form.youtube_url" />
        </section>

        <button class="button --primary" type="submit">
          <fa-icon icon="save"></fa-icon>
          <span class="ml-2">Simpan</span>
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      form: {},
      formDefault: {},
    };
  },
  methods: {
    fetchIdentity() {
      axios.get("api/profile/identity").then((response) => {
        this.form = response.data.school_identity;
      });
    },

    submitIdentity() {
      axios
        .post("api/profile/identity", this.form)
        .then(() => {
          this.$toasted.success("Identitas sekolah berhasil diperbarui");
        })
        .catch(() => {
          this.$toasted.error("Identitas sekolah gagal diperbarui");
        });
    },
  },
  mounted() {
    this.fetchIdentity();
  },
  name: "SchoolIdentity",
};
</script>

<style lang="scss" scoped>
.form-label {
  @apply uppercase tracking-wider mb-1 mt-4 block;
}

input[type="text"] {
  @apply w-full;
}
</style>