import { render, staticRenderFns } from "./SchoolIdentity.vue?vue&type=template&id=550ff165&scoped=true&"
import script from "./SchoolIdentity.vue?vue&type=script&lang=js&"
export * from "./SchoolIdentity.vue?vue&type=script&lang=js&"
import style0 from "./SchoolIdentity.vue?vue&type=style&index=0&id=550ff165&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "550ff165",
  null
  
)

export default component.exports